<script>
// Extensiones
import View from '@/components/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
  name: 'About',

  metaInfo: { title: 'About'},

  extends: View,

  mixins: [
    LoadSections([
      'about-header',
      'about-footer'
    ]),
  ],

  props: {
    id: {
      type: String,
      default: 'about',
    },
  },
}
</script>